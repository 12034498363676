import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_API_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseKey,{
	auth: {
	  persistSession: true,  // Keeps the session persistent across reloads
	  autoRefreshToken: true, // Automatically refresh tokens when needed
	},
  });

export default supabase;