import React, { useState, useEffect } from 'react';

// Utility function to check if the date string is in ISO format
function isISODate(value) {
  value = value.trim();
  const regex = /^\d{8}T\d{6}Z$/;
  return regex.test(value);
}

// Parse the date time string from the ISO format
function parseDateTime(dateTimeString) {
  const year = parseInt(dateTimeString.slice(0, 4), 10);
  const month = parseInt(dateTimeString.slice(4, 6), 10) - 1; // Months are zero-indexed
  const day = parseInt(dateTimeString.slice(6, 8), 10);
  const hours = parseInt(dateTimeString.slice(9, 11), 10);
  const minutes = parseInt(dateTimeString.slice(11, 13), 10);
  const seconds = parseInt(dateTimeString.slice(13, 15), 10);

  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

function TodaysEvents({ calendarId, apiKey, maxEvents }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const midnightTomorrow = new Date(tomorrow);
      midnightTomorrow.setHours(0, 0, 0, 0);

      const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}&timeMin=${today.toISOString()}&timeMax=${midnightTomorrow.toISOString()}&maxResults=${maxEvents}&orderBy=startTime&singleEvents=true`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const processedEvents = data.items.map((event) => ({
          ...event,
          start: isISODate(event.start.dateTime || event.start.date) ? parseDateTime(event.start.dateTime || event.start.date) : new Date(event.start.dateTime || event.start.date),
          end: isISODate(event.end.dateTime || event.end.date) ? parseDateTime(event.end.dateTime || event.end.date) : new Date(event.end.dateTime || event.end.date)
        }));
        setEvents(processedEvents);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching events:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchEvents();

    const intervalId = setInterval(fetchEvents, 1800000); // 1800000 ms = 30 minutes
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [calendarId, apiKey, maxEvents]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching events: {error.message}</p>;

  return (
    <div className="calendar_list">
      {events.length === 0 ? (
        <p>No events today</p>
      ) : (
        <ul className="calendar_event">
          {events.map((event) => (
            <li key={event.id}>
              <span className="fa-li"><i className="fa-regular fa-calendar-check"></i></span>
              {event.start.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).replace(/^0/, '')}&nbsp;to&nbsp;
              {event.end.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).replace(/^0/, '')}&nbsp;-&nbsp;
              {event.summary}
              <div className="description">{event.description || ''}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TodaysEvents;
