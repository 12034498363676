import React, { createContext, useState, useEffect } from 'react';
import supabase from '../lib/supabase';

const ScreenInfoContext = createContext();

const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const ScreenInfoProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(() => {
    const savedLocationId = localStorage.getItem('activeLocation');
    return savedLocationId ? JSON.parse(savedLocationId) : {id:0,name:''};
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await supabase
        .from('screen_config')
        .select('*, locations(*)')
        .eq('fingerprint', localStorage.getItem('fingerprint'));
      //console.log(data);
      if (!error) {
        //console.log(localStorage.getItem('fingerprint'),data);
          //console.log(data,{id: data?.[0]?.locations?.id,name: data?.[0]?.locations?.display_name});
          if (Array.isArray(data?.[0]?.locations)) {
            //console.log("is array");
            setLocations(data?.[0]?.locations);
            setActiveLocation(data?.[0]?.locations[0]);
          } else if (data?.[0]?.locations !== undefined) {
            //console.log("not array");
            setLocations([data?.[0]?.locations]);
            setActiveLocation(data?.[0]?.locations);
          } else {
            //console.log("else");
            setLocations([]);
            setActiveLocation(null);
          }
          //console.log(data?.[0]?.locations);
//setActiveLocation({id:0,name:''});
      } else {
        console.error("Error retrieving locations:",error);
      }
    }

    fetchLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      //console.log(locations);
      const foundItem = locations.find(item => item && item?.id === activeLocation?.id);
      if (!foundItem) {
        setActiveLocation(locations[0]);
      } else if (!areObjectsEqual(activeLocation, foundItem)) {
        setActiveLocation(foundItem);
      }
    }
  }, [locations, activeLocation.id]);

  useEffect(() => {
    if (activeLocation !== null) {
      localStorage.setItem('activeLocation', JSON.stringify(activeLocation));
    } else {
      localStorage.removeItem('activeLocation');
    }
  }, [activeLocation]);

  return (
    <ScreenInfoContext.Provider value={{ activeLocation, locations }}>
      {children}
    </ScreenInfoContext.Provider>
  );
};

export { ScreenInfoContext, ScreenInfoProvider };
